import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { query, collection, getDocs, where, orderBy } from "firebase/firestore"
import { db } from '../firebase_config.js';
import '../App.css'

import Arrowdown from '../images/renewal/Arrowdown_white.png';
import Subtract from '../images/renewal/Subtract.svg';
// import Back from '../images/renewal/Back.svg';
import ArrowBack from '../images/renewal/Arrow-down.svg';
import LogoMobile from '../images/renewal/MobileLogo.svg';

function DrawPage(props) {
    const { state } = useLocation();    //전 페이지에서 넘긴 매개변수 저장하는 변수
    const navigate = useNavigate();
    const [fileinfo, setFileinfo] = useState(state.fileinfo);
    const fromPage = state.from;

    const canvasRef = useRef(null);
    const [getCtx, setGetCtx] = useState(null);
    const [painting, setPainting] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [isHeaderVisible, setIsHeaderVisible] = useState(true);
    const [isWidthGreaterThanHeight, setIsWidthGreaterThanHeight] = useState(window.innerWidth > window.innerHeight);
    
    const [isStart, setIsStart] = useState(false);  //초기화할때 상태값 체크 변수
    const [color, setColor] = useState('black');    //펜 색깔
    const [penWidth, setPenWidth] = useState(4);    //펜 굵기

    const [contentList,setContentList] = useState([]);
    const [selTag, setSelTag] = useState(fileinfo.tag);
    const [isTagOpen, setIsTagOpen] = useState(false);
    const tagDropdownRef = useRef(null);
    const tagButtonRef = useRef(null);

    const [selectedStroke, setSelectedStroke] = useState('Stroke2'); //굵기 선택
    const [selectedColor, setSelectedColor] = useState('Color1'); //색깔 선택

    useEffect(() => {
        if(fromPage !== "patientcontent"){
            const fetchData = async () => {
                const q = query(collection(db, "contents"), where("sysdiv", "==", props.sysDiv), 
                                where("repcateval","==", fileinfo.repcateval),
                                orderBy("uploadtime","desc"));
                const snapshot = await getDocs(q);
            
                const data = snapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id,
                }));
                setContentList(data);
            }
        
            fetchData(); // fetchData 함수를 즉시 호출
        }
    }, [props, fileinfo.repcateval, fromPage])

    useEffect(() => {
        function handleClickOutside(event) {
            if (tagDropdownRef.current && !tagDropdownRef.current.contains(event.target)&&
                tagButtonRef.current && !tagButtonRef.current.contains(event.target)) {
                // setSelCategory(fileinfo.cateval);
                setSelTag(fileinfo.tag);
                setIsTagOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [fileinfo.cateval, fileinfo.tag]);

    useEffect(() => {
        const isWide = window.innerWidth > window.innerHeight;
        setIsWidthGreaterThanHeight(isWide);

        if (isWide) {
            const canvas = canvasRef.current;
            if (canvas) {  // canvas가 존재하는지 확인
                const img = new Image();
                img.src = fileinfo.fileAddr;
                
                img.onload = () => {
                    canvas.width = img.naturalWidth;
                    canvas.height = img.naturalHeight;
                    
                    const ctx = canvas.getContext("2d");
                    ctx.lineJoin = "round";
                    setGetCtx(ctx);
                }
            }
        }
    }, [fileinfo]);

    const drawFn = e => {
        if (!getCtx) return;
        
        setIsStart(true);
        
        const rect = canvasRef.current.getBoundingClientRect();
        const scaleX = canvasRef.current.width / rect.width;
        const scaleY = canvasRef.current.height / rect.height;
        
        const mouseX = (e.clientX - rect.left) * scaleX;
        const mouseY = (e.clientY - rect.top) * scaleY;
        
        if (!painting) {
            getCtx.beginPath();
            getCtx.moveTo(mouseX, mouseY);
        } else {
            getCtx.lineTo(mouseX, mouseY);
            getCtx.strokeStyle = color;
            getCtx.lineWidth = penWidth;
            getCtx.stroke();
        }
    }

    const getCanvasScale = () => {
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        return {
            scaleX: canvas.width / rect.width,
            scaleY: canvas.height / rect.height
        };
    }

    const touchStart = e => {
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const { scaleX, scaleY } = getCanvasScale();
        const touch = e.touches[0];
        
        const mouseX = (touch.clientX - rect.left) * scaleX;
        const mouseY = (touch.clientY - rect.top) * scaleY;
        
        getCtx.beginPath();
        getCtx.moveTo(mouseX, mouseY);
        setIsActive(true);
    }

    const touch = e => {
        setIsStart(true);
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const { scaleX, scaleY } = getCanvasScale();
        const touch = e.touches[0];
        
        const mouseX = (touch.clientX - rect.left) * scaleX;
        const mouseY = (touch.clientY - rect.top) * scaleY;
        
        if(!isActive) {
            getCtx.beginPath();
            getCtx.moveTo(mouseX, mouseY);
        } else {
            getCtx.lineTo(mouseX, mouseY);
            getCtx.strokeStyle = color;
            getCtx.lineWidth = penWidth;
            getCtx.stroke();
        }
    }

    const touchEnd = e => {
        if(!isActive) {
            return;
        } else {
            getCtx.beginPath();
        }
        
        setIsActive(false);
        return;
    }

    //초기화
    const handleReset = () => {
        getCtx.clearRect(0,0,canvasRef.current.width,canvasRef.current.height)
        setIsStart(false)
    }

    const toggleTagDropdown = () => {
        setIsTagOpen(!isTagOpen);
    };

    const toggleHeader = () => {
        setIsHeaderVisible(!isHeaderVisible);
    };

    const handleStrokeClick = async (stroke) => {
        setSelectedStroke(stroke);
        if(stroke === 'Stroke1'){
            setPenWidth(2);
        }else if(stroke === 'Stroke2'){
            setPenWidth(4);
        }else if(stroke === 'Stroke3'){
            setPenWidth(8);
        }
    };

    const handleColorClick = async (color) => {
        setSelectedColor(color);
        if(color === 'Color1'){
            setColor('#000');
        }else if(color === 'Color2'){
            setColor('#252525');
        }else if(color === 'Color3'){
            setColor('#E53122');
        }else if(color === 'Color4'){
            setColor('#0B07FA');
        }else if(color === 'Color5'){
            setColor('#3F7E15');
        }else if(color === 'Color6'){
            setColor('#ACEC95');
        }else if(color === 'Color7'){
            setColor('#FEFF41');
        }
    };

    const toDentalExplanPage = () => {
        navigate("/", {
            state: {
                cateval: fileinfo.cateval,
                repcateval: fileinfo.repcateval,
                sysdiv: "DC"
            }
        });
    };

    return (
        <div className="renew_background drawvideo" >
            {isWidthGreaterThanHeight ? (
                <div className="drawpage_content_area">
                {isHeaderVisible ? (
                    <div className="header_group_test">
                        <div style={{display:'flex'}}>
                            <div className="renew_video_back"
                                    onClick={()=>{
                                        if(fromPage !== "patientcontent"){
                                            toDentalExplanPage()
                                        }else{
                                            navigate(-1)
                                        }
                                    }}>
                                <img src={ArrowBack} alt='뒤로' style={{width:"1.5rem",height:"1.5rem"}} />
                            </div>
                            {fromPage !== "patientcontent" &&
                                <div style={{position:'relative'}}>
                                    <div ref={tagButtonRef}
                                        className="renew_content_sel"
                                        onClick={toggleTagDropdown}
                                    >
                                        <div>{selTag}</div>
                                        <img src={Arrowdown} alt='선택' style={{width:"1.5rem",height:"1.5rem"}}></img>
                                    </div>
                                    {isTagOpen && (
                                        <div className="renew_dropdown_menu" ref={tagDropdownRef}>
                                            {contentList
                                            .map(content => (
                                                <div key={content.id} 
                                                    style={{marginBottom:"1.64rem",cursor:'pointer',
                                                            color:selTag === content.tag ? "#FFF" : "#A9A9A9"}}
                                                    onClick={()=>{
                                                        if(content.filetype === "video"){
                                                            navigate("/viewVideo", {
                                                                state: {
                                                                    fileinfo: content,
                                                                },
                                                            });
                                                        }else{
                                                            setFileinfo(content);
                                                            setSelTag(content.tag);
                                                            toggleTagDropdown();
                                                        }
                                                    }}
                                                >{content.tag}</div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            }
                        </div>
                        <div className="draw_tool_desktop">
                            <div className="renew_back_del" style={{border:'1px solid #2D2D2D'}}>
                                <img src={Subtract} alt='그리기' style={{width:"1.5rem",height:"1.5rem"}}/>
                            </div>
                            <div style={{display:'flex',alignItems:'flex-start',gap:"0.625rem"}}>
                                <div className="renew_stroke_sel"
                                    style={{ border:selectedStroke === 'Stroke1' && '1px solid #949494'}}
                                    onClick={() => handleStrokeClick('Stroke1')}
                                >가늘게</div>
                                <div className="renew_stroke_sel"
                                    style={{ border:selectedStroke === 'Stroke2' && '1px solid #949494'}}
                                    onClick={() => handleStrokeClick('Stroke2')}
                                >보통</div>
                                <div className="renew_stroke_sel"
                                    style={{ border:selectedStroke === 'Stroke3' && '1px solid #949494'}}
                                    onClick={() => handleStrokeClick('Stroke3')}
                                >굵게</div>
                            </div>
                            <div style={{display:'flex',alignItems:'flex-start',gap:"0.625rem"}}>
                                <div className="renew_color_unit" onClick={() => handleColorClick('Color1')}
                                    style={{background:'#000',border:selectedColor === 'Color1' && '1px solid #949494'}}
                                />
                                <div className="renew_color_unit" onClick={() => handleColorClick('Color2')}
                                    style={{background:'#252525',border:selectedColor === 'Color2' && '1px solid #949494'}}
                                />
                                <div className="renew_color_unit" onClick={() => handleColorClick('Color3')}
                                    style={{background:'#E53122',border:selectedColor === 'Color3' && '1px solid #949494'}}
                                />
                                <div className="renew_color_unit" onClick={() => handleColorClick('Color4')}
                                    style={{background:'#0B07FA',border:selectedColor === 'Color4' && '1px solid #949494'}}
                                />
                                <div className="renew_color_unit" onClick={() => handleColorClick('Color5')}
                                    style={{background:'#3F7E15',border:selectedColor === 'Color5' && '1px solid #949494'}}
                                />
                                <div className="renew_color_unit" onClick={() => handleColorClick('Color6')}
                                    style={{background:'#ACEC95',border:selectedColor === 'Color6' && '1px solid #949494'}}
                                />
                                <div className="renew_color_unit" onClick={() => handleColorClick('Color7')}
                                    style={{background:'#FEFF41',border:selectedColor === 'Color7' && '1px solid #949494'}}
                                />
                            </div>
                            {/* <div className="renew_back_del" style={{cursor:'pointer'}} onClick={()=>toDentalExplanPage()}>
                                <img src={Back} alt='이전으로' style={{width:20,height:20}} />
                            </div> */}
                            <div className="renew_back_del" style={{padding:"0.5625rem 0.375rem",color:'#FFF',fontSize:"0.875rem",
                                fontWeight:500,cursor:'pointer'}} onClick={handleReset}
                            >필기삭제
                            </div>
                            <div className="renew_back_del" style={{padding:"0.5625rem 0.375rem",color:'#FFF',fontSize:"0.875rem",
                                fontWeight:500,cursor:'pointer'}} onClick={toggleHeader}
                            >숨기기
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="show_header_button" style={{border:'1px solid #2D2D2D'}} onClick={toggleHeader}>
                        <img src={Subtract} alt='그리기' style={{width:"1.5rem",height:"1.5rem"}}/>
                    </div>
                )}
                    <div style={{touchAction:'none',marginTop:'1rem'}}>
                        <img src={fileinfo.fileAddr} alt='이미지' className='draw_background_img' />
                        <canvas 
                            id="drawingCanvas"
                            ref={canvasRef}
                            onMouseDown={() => setPainting(true)}
                            onMouseUp={() => setPainting(false)}
                            onMouseMove={e => drawFn(e)}
                            onMouseLeave={() => setPainting(false)}
                            onTouchStart={e => touchStart(e)}
                            onTouchMove={e => touch(e)}
                            onTouchEnd={e => touchEnd(e)}
                        ></canvas>
                    </div>
                    {isHeaderVisible && (
                        <div className="draw_tool_tablet_test">
                            <div style={{display:'inline-flex',padding:"0.375rem",alignItems:'center',gap:"0.625rem",
                                borderRadius:"0.5rem",background:'#2D2D2D',whiteSpace:'nowrap'}}>
                                <div className="renew_back_del" style={{border:'1px solid #2D2D2D'}}>
                                    <img src={Subtract} alt='그리기' style={{width:"1.5rem",height:"1.5rem"}}/>
                                </div>
                                <div style={{display:'flex',alignItems:'flex-start',gap:"0.625rem"}}>
                                    <div className="renew_stroke_sel"
                                            style={{ border:selectedStroke === 'Stroke1' && '1px solid #949494'}}
                                            onClick={() => handleStrokeClick('Stroke1')}
                                    >가늘게</div>
                                    <div className="renew_stroke_sel"
                                            style={{ border:selectedStroke === 'Stroke2' && '1px solid #949494'}}
                                            onClick={() => handleStrokeClick('Stroke2')}
                                    >보통</div>
                                    <div className="renew_stroke_sel"
                                            style={{ border:selectedStroke === 'Stroke3' && '1px solid #949494'}}
                                            onClick={() => handleStrokeClick('Stroke3')}
                                    >굵게</div>
                                </div>
                                <div style={{display:'flex',alignItems:'flex-start',gap:"0.625rem"}}>
                                    <div className="renew_color_unit" onClick={() => handleColorClick('Color1')}
                                            style={{background:'#000',border:selectedColor === 'Color1' && '1px solid #949494'}}
                                    />
                                    <div className="renew_color_unit" onClick={() => handleColorClick('Color2')}
                                            style={{background:'#252525',border:selectedColor === 'Color2' && '1px solid #949494'}}
                                    />
                                    <div className="renew_color_unit" onClick={() => handleColorClick('Color3')}
                                            style={{background:'#E53122',border:selectedColor === 'Color3' && '1px solid #949494'}}
                                    />
                                    <div className="renew_color_unit" onClick={() => handleColorClick('Color4')}
                                            style={{background:'#0B07FA',border:selectedColor === 'Color4' && '1px solid #949494'}}
                                    />
                                    <div className="renew_color_unit" onClick={() => handleColorClick('Color5')}
                                            style={{background:'#3F7E15',border:selectedColor === 'Color5' && '1px solid #949494'}}
                                    />
                                    <div className="renew_color_unit" onClick={() => handleColorClick('Color6')}
                                            style={{background:'#ACEC95',border:selectedColor === 'Color6' && '1px solid #949494'}}
                                    />
                                    <div className="renew_color_unit" onClick={() => handleColorClick('Color7')}
                                            style={{background:'#FEFF41',border:selectedColor === 'Color7' && '1px solid #949494'}}
                                    />
                                </div>
                                <div className="renew_back_del" style={{padding:"0.5625rem 0.375rem",color:'#FFF',fontSize:"0.875rem",
                                    fontWeight:500,cursor:'pointer'}} onClick={handleReset}
                                >필기삭제
                                </div>
                                <div className="renew_back_del" style={{padding:"0.5625rem 0.375rem",color:'#FFF',fontSize:"0.875rem",
                                    fontWeight:500,cursor:'pointer'}} onClick={toggleHeader}
                                >숨기기
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div style={{width:window.innerWidth,height:window.innerHeight,display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                    <img src={LogoMobile} alt="모바일로고" style={{width:"5rem",height:"6.125",flexShrink:0,marginBottom:"1.56rem"}} />
                    <div>더 나은 서비스를 위해</div>
                    <div>가로가 세로보다 큰 화면으로 이용해주세요.</div>
                </div>
            )}
        </div>
    )
}

export default DrawPage