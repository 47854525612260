import React from 'react';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../firebase_config.js';
import { signOut } from "firebase/auth";
import { getDocs, doc, collection, updateDoc, query, where } from "firebase/firestore";
import Swal from "sweetalert2";

function AdminDataMod(props) {
    const navigate = useNavigate();

    const fieldAdd = async () => {
        try{     
            // const now = new Date();
            const collectionRef = collection(db, 'evapatient');
            // const snapshot = await getDocs(collectionRef);
            const q = query(collectionRef, where("diagtimestart", "==", null)
                        );
            const querySnapshot = await getDocs(q); //users 테이블의 userId 중에 입력한 이메일주소가 있는지;

            querySnapshot.forEach(async (docSnapshot) => {
                // const { groupid } = docSnapshot.data();
                // groupid 필드가 nocode가 아닌 경우에만 업데이트
                // if (groupid === "nocode") {
                //     const docRef = doc(db, "users", docSnapshot.id);
                //     await updateDoc(docRef, {
                //         groupid: "nogroup"
                //     });
                // }
                const docRef = doc(db, "evapatient", docSnapshot.id);
                await updateDoc(docRef, {
                    diagtimestart: "",
                    diagtimeend: ""
                });
            });

            Swal.fire({
                icon: "success",
                title: "데이터수정완료",
                text: "데이터수정완료",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            }).then((res) => {
                /* Read more about isConfirmed, isDenied below */
                if (res.isConfirmed) {
                    //확인 요청 처리
                }
            });
        } catch(error) {
            console.log("에러메세지:", error.message);
        }
    }

    const fieldUpdate = async () => {
        try {
            const collectionRef = collection(db, 'contents');
            const q = query(collectionRef, 
                where("cateval", "==", "사랑니&외과진료"),
                where("repcateval", "==", "사랑니&외과진료"),
                where("sysdiv", "==", "DC")
            );
            
            const snapshot = await getDocs(q);

            // snapshot.forEach((doc) => {
            //     console.log(doc.id, " => ", doc.data());
            // });
            
            snapshot.forEach((docSnapshot) => {
                updateDoc(docSnapshot.ref, {
                    repcateval: "사랑니외과진료"
                });
            });
            
    
            Swal.fire({
                icon: "success",
                title: "필드 추가 완료",
                // text: `${snapshot.size}개의 문서가 삭제되었습니다.`,
                confirmButtonText: "확인",
            });
        } catch(error) {
            console.log("에러메세지:", error.message);
            Swal.fire({
                icon: "error",
                title: "필드 추가 실패",
                text: error.message,
                confirmButtonText: "확인",
            });
        }
    }

    return (
      <div className="wrap">
        <div className="content_wrap">
            <h3 className="sub_tit">
                <span>데이터 수정</span>
            </h3>
            <div className="mngconnect_wrap">
                <div className="patientlist_body">
                    <button className="admin_common_button_1" onClick={(e)=>{
                            e.preventDefault();
                            fieldUpdate();
                        }
                    }>등록</button>
                </div>
            </div>
        </div>
        <footer>
          <button className="footer_logout"
                  onClick={()=>{
                      signOut(auth);
                      console.log("로그아웃");
                      navigate("/"); //페이지 이동
                      window.location.reload(false);  //페이지 refresh
                  }}
          >로그아웃</button>
          <span className="copyright">Copyright 2022. Dental Connect All rights reserved.</span>
        </footer>
      </div>
    )
}

export default AdminDataMod;